export const useUtils = () => {
    const randomHash = (
        format: 'alnum' | 'alpSpecLower' | 'alpha' | 'alphaLower' | 'alpNumLower' | 'hexdec' | 'numeric' | 'nozero',
        length: number = 8,
    ) => {
        let pool = '';
        switch (format) {
            case 'alnum':
                pool = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
                break;
            case 'alpSpecLower':
                pool = 'abcdefghijklmnopqrstuvwxyz-_';
                break;
            case 'alpha':
                pool = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
                break;
            case 'alphaLower':
                pool = 'abcdefghijklmnopqrstuvwxyz';
                break;
            case 'alpNumLower':
                pool = '0123456789abcdefghijklmnopqrstuvwxyz';
                break;
            case 'hexdec':
                pool = '0123456789abcdef';
                break;
            case 'numeric':
                pool = '0123456789';
                break;
            case 'nozero':
                pool = '123456789';
                break;
            default:
                return '';
        }

        let buf = '';

        for (let i = 0; i < length; i++) {
            buf += pool.charAt(Math.floor(Math.random() * pool.length));
        }
        return buf;
    };
    return {
        randomHash,
    };
};

export const badgeIcon = (badgeName: string | undefined) => {
    if (!badgeName) return;
    const componentName = badgeName.replaceAll('_', '-');
    return componentName;
};
